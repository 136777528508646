<template>
  <v-card
    tile
    flat
    color="transparent"
    max-width="410"
    class="create-account-with-email mx-auto"
  >
    <div class="d-block justify-center">
      <v-form ref="form" @submit.prevent="">
        <div class="mb-4 input-label">E-mail</div>
        <v-text-field
          v-model="email"
          single-line
          outlined
          height="52"
          class="rounded-lg"
          :placeholder="$t('login.typeEmail')"
          :rules="[rules.required, rules.validEmail, rules.emailNotInUse]"
          type="email"
          :disabled="loading"
          autocomplete="username"
          @input="handleInputChange"
        />
        <div class="mb-4 input-label">{{ $t("common.password") }}</div>
        <v-text-field
          v-model="password"
          single-line
          outlined
          class="rounded-lg"
          :placeholder="$t('login.typePassword')"
          :disabled="loading"
          :rules="passwordRules"
          :append-icon="hidePass ? 'ph-fill ph-eye-slash' : 'ph-fill ph-eye'"
          :type="hidePass ? 'password' : 'text'"
          @click:append="hidePass = !hidePass"
          autocomplete="current-password"
          @input="handleInputChange"
        />
        <div class="mb-4 input-label">
          {{ $t("login.repeatPassword") }}
        </div>
        <v-text-field
          ref="repeatPassword"
          v-model="repeatPassword"
          single-line
          outlined
          class="rounded-lg"
          :class="formIsValid ? '' : 'mb-7'"
          :style="formIsValid ? 'margin-bottom: -8px' : ''"
          :placeholder="$t('login.typePasswordAgain')"
          :disabled="loading"
          :rules="[rules.equalPasswords]"
          :append-icon="
            hideRepeatPass ? 'ph-fill ph-eye-slash' : 'ph-fill ph-eye'
          "
          :type="hideRepeatPass ? 'password' : 'text'"
          @click:append="hideRepeatPass = !hideRepeatPass"
          @input="handleInputChange"
        />
        <v-checkbox
          v-model="readAndAgreed"
          class="mt-0 pt-0 pb-2"
          :disabled="loading"
          :rules="[rules.marked]"
          @change="handleInputChange"
        >
          <template #label>
            <div
              class="login-checkbox-text"
              v-html="$t('login.readAndAgreed')"
            />
          </template>
        </v-checkbox>
        <div
          v-show="showRecaptcha"
          :class="recaptchaStatus === 'not-verified' ? 'mb-7' : 'mb-3'"
        >
          <div class="d-flex align-center flex-column">
            <div
              id="recaptcha-container"
              style="z-index: 1; width: 304px; height: 78px"
            />
            <v-input
              :error-messages="
                recaptchaStatus === 'not-verified'
                  ? $t('login.recaptchaError')
                  : undefined
              "
              style="z-index: 0; margin-left: -40px"
              :style="isPortrait ? 'margin-top: -16px' : ''"
            />
          </div>
        </div>
        <div class="mb-6">
          <v-btn
            block
            color="#613FC8B2"
            height="48"
            class="text-none white--text rounded-lg login-submit-button"
            @click="signIn"
            :loading="loading"
            :disabled="loading"
          >
            <div class="enter-text">{{ $t("login.register") }}</div>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import {
  getAuth,
  useDeviceLanguage,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { mapGetters, mapMutations } from "vuex";

import { DEFAULT_PASSWORD_STRENGTH } from "@/helpers/variables/backendConstants";
import { rules } from "@/helpers/services/utils";

export default {
  name: "CreateAccountWithEmail",

  data() {
    return {
      email: "",
      password: "",
      repeatPassword: "",
      hidePass: true,
      hideRepeatPass: true,
      loading: false,
      rules: {
        ...rules,

        min_len: (v) =>
          (!!v && v.length >= this.minLen) ||
          `${this.$t("textFieldRules.minCharacters")}: ${this.minLen}`,

        validEmail: (e) =>
          !!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(e) ||
          this.$t("login.typeValidEmail"),

        emailNotInUse: (e) =>
          !this.alreadyInUse.includes(e) || this.$t("login.typeEmailNotInUse"),

        equalPasswords: (v) =>
          v === this.password || this.$t("login.passwordsDoNotMatch"),

        marked: (v) => !!v,
      },
      readAndAgreed: false,
      recaptchaStatus: "not-showing",
      minLen: 6,
      formIsValid: true,
      alreadyInUse: [],
      isPortrait: false,
    };
  },

  computed: {
    ...mapGetters(["showOnboardingUser", "nextPage"]),

    passwordRules() {
      const passwordStrength = {
        ...DEFAULT_PASSWORD_STRENGTH,
        has_lower: false,
        has_number: false,
        has_symbol: false,
        has_upper: false,
        min_len: this.minLen,
      };

      return Object.entries(passwordStrength)
        .filter((entry) => entry[1])
        .map((entry) => this.rules[entry[0]])
        .concat([this.rules.required]);
    },

    inputsFilled() {
      return (
        this.email && this.password && this.repeatPassword && this.readAndAgreed
      );
    },

    showRecaptcha() {
      return this.recaptchaStatus !== "not-showing";
    },
  },

  methods: {
    ...mapMutations(["setToken", "setTokenReseller"]),

    validateForm() {
      return this.$refs.form.validate();
    },

    async verifyRecaptcha() {
      if (window.recaptchaVerifier) {
        const widgetId = await window.recaptchaVerifier.render();
        const recaptchaResponse = window.grecaptcha.getResponse(widgetId);
        this.recaptchaStatus =
          recaptchaResponse.length > 0 ? "verified" : "not-verified";
        return this.showRecaptcha;
      }

      const auth = getAuth();
      useDeviceLanguage(auth);

      this.recaptchaStatus = "showing";

      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            width: 410,
            height: 66,
            callback() {
              this.recaptchaStatus = "verified";
            },
            "expired-callback": (a) => console.error(a),
          },
          auth
        );
      } catch (error) {
        console.log(error);
        if (error.message.includes("auth/network-request-failed")) {
          setTimeout(this.verifyRecaptcha, 3000);
        }
      }

      if (!window.recaptchaVerifier) return this.showRecaptcha;

      await window.recaptchaVerifier.render();

      return this.showRecaptcha;
    },

    checkPasswordError() {
      setTimeout(() => {
        this.formIsValid =
          !this.$refs.repeatPassword.$el.classList.contains("error--text");
      }, 200);
    },

    async handleInputChange() {
      this.checkPasswordError();

      if (!this.inputsFilled || !this.validateForm()) return;

      await this.verifyRecaptcha();
    },

    async createEmailUser() {
      const auth = getAuth();

      let userCredential;
      try {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
      } catch (error) {
        console.error(error);
        if (error.message.includes("auth/email-already-in-use")) {
          this.alreadyInUse.push(this.email);
          this.validateForm();
        }
      }
      return userCredential;
    },

    async authorizate(userCredential) {
      const idToken = await userCredential.user.getIdToken();
      const url = `${process.env.VUE_APP_API_BASE_URL}/auth/email`;
      let response;
      try {
        response = await this.$axios.get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
      } catch (error) {
        console.error(error);
      }
      if (!response) return;

      this.setToken(response.data.token);
      // this.setTokenReseller(tokenReseller); // TODO Reseller login
      localStorage.setItem("show_onboarding", this.showOnboardingUser);
      localStorage.setItem("companyOauth", "email");
      this.$router.push({ name: "Install" });
    },

    async signIn() {
      this.loading = true;

      if (!this.validateForm()) return (this.loading = false);

      if (!(await this.verifyRecaptcha())) return (this.loading = false);

      if (this.recaptchaStatus !== "verified") {
        this.recaptchaStatus = "not-verified";
        return (this.loading = false);
      }

      const userCredential = await this.createEmailUser();

      if (!userCredential) return (this.loading = false);

      await this.authorizate(userCredential);

      this.loading = false;
    },

    handleOrientation() {
      this.isPortrait = window?.screen?.orientation?.type?.includes("portrait");
      screen?.orientation?.addEventListener?.("change", (event) => {
        const type = event.target.type;
        this.isPortrait = type.includes("portrait");
      });
    },
  },
  beforeMount() {
    this.handleOrientation();
  },
  beforeDestroy() {
    screen?.orientation?.removeEventListener?.(
      "change",
      this.handleOrientation
    );
  },
};
</script>

<style>
.create-account-with-email .v-input--selection-controls__input {
  margin-bottom: 16px;
}

.create-account-with-email .v-input__slot {
  margin-bottom: 4px;
}
</style>
