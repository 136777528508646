<template>
  <!-- MOBILE SCREEN -->
  <v-dialog
    v-if="$vuetify.breakpoint.mobile"
    v-model="showError"
    :width="fullWidth ? '100%' : ''"
    :max-width="fullWidth ? '100%' : 500"
    origin="top"
    transition="scroll-y-transition"
  >
    <v-card
      :tile="tile"
      color="error"
      class="brown--text text--darken-3 font-weight-medium"
    >
      <v-progress-linear
        v-if="showTopProgress"
        color="error darken-4"
        indeterminate
        rounded
        height="6"
      />

      <v-row align="center" class="ma-0 py-2 px-4" dense>
        <v-col
          v-if="!hideIcon && $vuetify.breakpoint.smAndUp"
          cols="1"
          class="pr-0 d-flex justify-center"
        >
          <v-icon v-text="'ph-fill ph-warning'" />
        </v-col>
        <v-col class="text-wrap break-word text-body-2">
          <span :class="`font-montserrat ${textClass}`" v-html="errorMessage" />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.smAndUp ? 1 : 2"
          class="d-flex justify-end pr-0 pl-2"
          v-if="!hideClose"
        >
          <v-btn icon large @click="showError = false">
            <v-icon v-text="'ph-x'" />
          </v-btn>
        </v-col>
      </v-row>

      <v-progress-linear
        v-if="showBottomProgress"
        color="error darken-4"
        indeterminate
        rounded
        height="6"
      />
    </v-card>
  </v-dialog>

  <!-- DESKTOP SCREEN -->
  <v-alert
    v-else-if="!$vuetify.breakpoint.mobile && showError"
    :icon="false"
    type="error"
    dismissible
    :tile="tile"
    transition="scroll-y-transition"
    class="pa-2"
  >
    <v-row align="center" class="ma-0 pa-0" dense>
      <v-col
        v-if="!hideIcon && $vuetify.breakpoint.smAndUp"
        cols="1"
        class="mr-2"
      >
        <v-icon v-text="'ph-fill ph-warning'" />
      </v-col>
      <v-col class="text-wrap break-word text-body-2">
        <span :class="`font-montserrat ${textClass}`" v-html="errorMessage" />
      </v-col>
    </v-row>

    <template v-if="!hideClose" #close>
      <v-btn icon @click="showError = false">
        <v-icon v-text="'ph-x'" />
      </v-btn>
    </template>
  </v-alert>
</template>
<script>
export default {
  name: "ErrorDialog",

  props: {
    closeTimeout: { type: [String, Number], default: 0 },
    errorMessage: { type: String, default: "" },
    fullWidth: { type: Boolean, default: true },
    hideClose: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    progress: { type: Boolean, default: false },
    progressAlign: { type: String, default: "bottom" },
    show: { type: Boolean, default: false },
    textClass: { type: String, default: "" },
    tile: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  computed: {
    hasTimeout() {
      return parseInt(this.closeTimeout) > 0;
    },

    showError: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    showBottomProgress() {
      return this.progress && this.progressAlign === "bottom";
    },

    showTopProgress() {
      return this.progress && this.progressAlign === "top";
    },
  },

  watch: {
    showError() {
      this.setErrorTimeout();
    },
  },

  methods: {
    setErrorTimeout() {
      if (this.showError && this.hasTimeout) {
        setTimeout(() => {
          this.showError = false;
        }, parseInt(this.closeTimeout));
      }
    },
  },

  beforeMount() {
    this.setErrorTimeout();
  },
};
</script>
