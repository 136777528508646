<template>
  <v-menu offset-y max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary--text"
        class="text-none caption px-4"
        text
        x-small
        v-bind="attrs"
        v-on="on"
      >
        {{ language }}
        <v-icon v-text="'ph-fill ph-caret-down'" small class="ml-1" />
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(item, index) in allLanguages"
        :key="index"
        @click="saveLocale(item.lang)"
      >
        <v-list-item-title class="py-0">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SelectLanguage",
  data() {
    return {
      lang: "pt-br",
    };
  },

  computed: {
    ...mapGetters(["allLanguages"]),

    language() {
      return this.allLanguages.find((item) => item.lang == this.lang).title;
    },
  },

  methods: {
    ...mapMutations(["setLocale"]),
    saveLocale(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang == "pt-br" ? "pt" : lang;
      localStorage.setItem("lang", lang);
      this.lang = lang;
      this.setLocale(lang);
    },
  },
  beforeMount() {
    this.saveLocale(this.lang);
  },
};
</script>
